<template>
  <div id="settingindex">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name:'SettingIndex',
  components: {

  },
  data() {
    return {

    }
  },
  methods:{},
}
</script>

<style scoped>
#settingindex{
  position: relative;
  padding-top: 1.5%;
  padding-left: 60px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
</style>
